/* ContentCard.css */
.contentCard{}

.contentCard-tall{
	vertical-align: top;
	margin:9px;
	width:300px;
		/* for now */
	height:400px;
	padding:9px;
	display:inline-block;
}
	.contentCard-tall div{
		height:300px;
		display:inline;
	}

.contentCard-wide{
	margin:9px 0;
	width:100%;
	padding:16px;
	display:inline-block;
}
	.contentCard-wide div{
		margin:0 16px 0 0;
		/* height:100px; */
		display:inline;
	}
	.contentCard-wide .contentCardImageContainer{
		float:left;
	}

.contentCard-ownerSettings{
	position:absolute;
	bottom:16px;
	right:16px;
}