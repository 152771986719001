/* index.css */
html{
	height:100%;
	background-color: #F4F4F4;
}
.spacing-16px{margin:8px;}
body {
	height:100%;
	margin: 0;
	font-family: 'Roboto', 'Oxygen',
    	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: transparent;
}
p{
	margin:0;
}

#root{}
.page{
	/* position: absolute; */
	margin-top:5%;
	width:100%;
	height:100%;
	display:inline-block;
}
	.paddedPage{
		padding:0 16px;
	}

.default-transition{
	transition: 400ms all ease-in-out;
	-webkit-transition: 400ms all ease-in-out;
	-moz-transition: 400ms all ease-in-out;
	-o-transition: 400ms all ease-in-out;
}

.margin-auto{
	margin:auto
}
.marginTop-5pct{
	/* margin-top:5%; */
}

.fullWidth{
	width:100%;
	background-color: transparent;
}
	.width-0Percent{
		width:0
	}
	.width-15percent{
		/* background-color: #0f0; */
		width:15%;
	}
	.width-85percent{
		width:85%;
	}

/* ALIGNMENT */
	.floatLeft{
	float:left;
	}
	.floatRight{
		float:right;
	}
	.verticalAlignBottom{
		vertical-align: bottom;
	}

	.textAlign-left{
		text-align: left;
	}
	.textAlign-center{
		text-align: center;
	}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

form{}
	.row{
	}
	
	.bootstrap-form div{
		margin:9px 0;
		padding-left:0;
	}

	/* information field (organize) */
		#form-information{
			margin:0;
		}

img{}
	.userImage{
		border-radius:100%;
	}

.card{
	background-color: rgba(255, 255, 255, 0.36);
	border-radius:9px;
	border: none;
}
	.card:hover{
		background-color: rgba(255, 255, 255, 0.81);
	}

.formErrorText{
	color:#F00;
}

.btn-check:focus+.btn-outline-danger, .btn-check:focus+.btn,
	.btn:focus, .btn-check:focus+.btn, .btn:focus{
	/* override bootstrap.min.css, without `!important`, it works for the searchbar button group (first line); */
	outline:none !important;
	box-shadow: none !important;
}