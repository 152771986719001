/* UserCard.css */
.userCard{}

.userCard-tall{
	margin:0 auto;
	width:50%;
	padding:9px;
	display:block;
	text-align: center;
}
	.userCard-tall div{
		height:300px;
		display:inline;
	}
.userCard-wide{
	margin:0;
	width:100%;
	padding:16px;
	display:inline-block;
}
	.userCard-wide div{
		margin:0 16px;
		height:100px;
		display:inline;
	}
	.userCard-wide .userCardImageContainer{
		float:left;
	}

.small-userImage{
	height:100px;
}
.large-userImage{
	
}