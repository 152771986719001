/* NavBar.css */
#navigationTable{
	position: fixed;
	z-index:9999;
	background-color:rgba(255, 255, 255, 0.81);	/*fallback*/
	background:rgba(255, 255, 255, 0.81);	/*fallback*/
	background:	var(--blur-background);	/*fallback*/
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
}
.navbarButton-container{

	padding:9px 4px;

}
	#navigationtd-homeIcon{
		padding: 9px 3px 9px 24px;
	}
	#navigationtd-message{
		text-align: center;
	}

	.nav-pfp img{
		height:32px;
		border-radius:100%;
	}